import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/TeamMemberLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bridget Hennigar, the Chief Operating Officer of CLHbid.com, has life-long roots in the agricultural community. She obtained her Bachelor of Commerce with Distinction from the University of Alberta, followed by her Chartered Accountant designation. She has chaired many community organizations and received various awards for her leadership contributions, including the Fellow of Chartered Accountants.`}</p>
    <p>{`As a chartered professional accountant, she has worked extensively with farmers and ranchers, effectively structuring their business and assisting them in managing and planning their business. She has worked with owners and their lenders in the process of acquiring capital, and has provided advice upon the exit of their business.`}</p>
    <p>{`As former Regional Managing Partner of a national accounting and business advisory firm, Bridget understands diverse business situations. Her enthusiasm and business leadership has enabled her region to grow over 10 times during her tenure. Bridget’s leadership experience on many Firm initiatives taught her a collaborative approach to solving business issues.`}</p>
    <p>{`Bridget works with clients of CLHbid.com, their accountants and tax specialists to provide unique strategic plans upon exit of their farming business. With her pragmatic attitude and dedication, she wants to extend her hand to your family, business and community.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      